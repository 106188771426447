export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 48,
  w: 1000,
  h: 1000,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [4.629, 30.629, 0], ix: 2, l: 2 },
        a: { a: 0, k: [959.894, 420.889, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [31.373, 31.373, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 21,
              s: [42.373, 42.373, 100]
            },
            { t: 45, s: [31.373, 31.372, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-33.485, 2.381],
                    [-5.991, 25.226],
                    [0, 0],
                    [0.008, 2.71],
                    [0, 0],
                    [0, 0],
                    [0, 12.584],
                    [0, 0],
                    [11.94, 0],
                    [0, 0],
                    [0.597, -0.05],
                    [0.573, 0],
                    [0, 0],
                    [3.403, -3.58],
                    [0.02, -5.065],
                    [0, 0],
                    [9.675, -0.719],
                    [-2.129, -33.252]
                  ],
                  o: [
                    [2.129, 33.253],
                    [27.344, -1.941],
                    [0, 0],
                    [1.062, -2.47],
                    [0, 0],
                    [0, 0],
                    [11.93, 0],
                    [0, 0],
                    [0, -12.585],
                    [0, 0],
                    [-0.616, 0],
                    [-0.554, -0.055],
                    [0, 0],
                    [-4.808, 0.013],
                    [-3.402, 3.579],
                    [0, 0],
                    [-8.978, -3.867],
                    [-33.495, 2.38],
                    [0, 0]
                  ],
                  v: [
                    [-88.968, 71.539],
                    [-24.465, 127.44],
                    [30.806, 80.659],
                    [30.804, 80.659],
                    [32.403, 72.797],
                    [32.403, -84.069],
                    [69.284, -84.069],
                    [91.098, -106.945],
                    [91.098, -106.99],
                    [69.284, -129.821],
                    [17.85, -129.821],
                    [16.031, -129.735],
                    [14.342, -129.821],
                    [14.299, -129.821],
                    [1.48, -124.212],
                    [-3.863, -110.716],
                    [-3.863, 11.81],
                    [-32.219, 7.024],
                    [-88.969, 71.539]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [958.892, 422.001], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 49,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
        a: { a: 0, k: [4.629, 30.629, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.379, 0.379, 0.667], y: [1, 1, 1] },
              o: { x: [0.607, 0.607, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.429, 0.429, 0.667], y: [1, 1, 1] },
              o: { x: [0.647, 0.647, 0.333], y: [0, 0, 0] },
              t: 22.272,
              s: [121, 121, 100]
            },
            { t: 48, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [247.258, 247.258], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.207843137255, 0.325490196078, 0.858823529412, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [4.629, 30.629], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 49,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.468], y: [1] },
              o: { x: [0.541], y: [0] },
              t: 6,
              s: [20]
            },
            { t: 37, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [498.731, 504.26, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-737.269, -113.269, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.497, 0.497], y: [1, 1] },
                    o: { x: [0.511, 0.511], y: [0, 0] },
                    t: 0,
                    s: [232.461, 232.461]
                  },
                  { t: 37, s: [816.461, 816.461] }
                ],
                ix: 2
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.207843152214, 0.325490196078, 0.85882358925, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-737.269, -113.269], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 49,
      st: -19,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.462], y: [1] },
              o: { x: [0.546], y: [0] },
              t: 9,
              s: [40]
            },
            { t: 39, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [498.731, 504.26, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-737.269, -113.269, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.497, 0.497], y: [1, 1] },
                    o: { x: [0.511, 0.511], y: [0, 0] },
                    t: 2,
                    s: [171.461, 171.461]
                  },
                  { t: 39, s: [692.461, 692.461] }
                ],
                ix: 2
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.207843152214, 0.325490196078, 0.85882358925, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-737.269, -113.269], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 49,
      st: -19,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.462], y: [1] },
              o: { x: [0.546], y: [0] },
              t: 12,
              s: [60]
            },
            { t: 42, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [498.731, 504.26, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-737.269, -113.269, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.497, 0.497], y: [1, 1] },
                    o: { x: [0.511, 0.511], y: [0, 0] },
                    t: 5,
                    s: [85.461, 85.461]
                  },
                  { t: 42, s: [525.461, 525.461] }
                ],
                ix: 2
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.207843152214, 0.325490196078, 0.85882358925, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-737.269, -113.269], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 49,
      st: -19,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
